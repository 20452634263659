export const Permission = {
  persoon: {
    creeerBis: 'Persoon.CreeerBis',
    geefGezinssamenstelling: 'Persoon.GeefGezinssamenstelling',
    geefHistoriekGezinssamenstelling: 'Persoon.GeefHistoriekGezinssamenstelling',
    geefHistoriekPersoon: 'Persoon.GeefHistoriekPersoon',
    geefPersoon: 'Persoon.GeefPersoon',
    wijzigKSZPersoon: 'Persoon.WijzigKSZPersoon',
    zoekPersoonOpAdres: 'Persoon.ZoekPersoonOpAdres',
    zoekPersoonOpNaam: 'Persoon.ZoekPersoonOpNaam',
  },
  LED: {
    geefBewijs: 'LED.GeefBewijs',
    registreerBewijs: 'LED.RegistreerBewijs',
    annuleerBewijs: 'LED.AnnuleerBewijs',
    bewijsMetadata: 'LED.RegistreerBewijsMetadata',
  },
  document: {
    verstuurEmail: 'messages.files/upload/EMAIL',
    verstuurBrief: 'messages.files/upload/PAPER',
    verstuurEboxBurger: 'messages.publishMessageCitizen',
    verstuurEboxOnderneming: 'messages.publishMessageEnterprise',
    zoekVerzendingStatus: 'messages.searchMessageStatus',
    zoekEboxStatus: 'messages.eboxPreferences/search',
    zoekVerzending: 'messages.messages',
    statusRequest: 'messages.statusRequest',
    attachments: 'messages.messages/*/attachments',
    metadata: 'messages.messages/*/metadata',
  },
  logging: {
    zoekLegalLog: 'Logging.ZoekLegalLog',
  },
  rapportering: {
    zoek: 'Rapportering.Rapportering',
  },
  mobiliteit: {
    binnenlandsVoertuig: 'mobility.registrationsTitular',
    buitenlandsVoertuig: 'mobility.crossborderTitulars',
  },
  attesten: {
    attesten: 'healthAttestations.healthAttestations',
    metadata: 'healthAttestations.healthAttestations/*/metadata',
    sendDocument: 'healthAttestations.healthAttestations/*/sendDocumentRequest',
    generateDocument: 'healthAttestations.healthAttestations/*/generateDocument',
    download: 'healthAttestations.healthAttestations/*/document',
    recreate: 'healthAttestations.healthAttestations/recreateRequest',
    oneDoseRequired: 'healthAttestations.healthAttestations/*/oneDoseRequired',
    ctpc: {
      attesten: 'healthAttestations.CTPCAttestations',
      metadata: 'healthAttestations.CTPCAttestations/*/metadata',
      generateDocument: 'healthAttestations.CTPCAttestations/*/generateDocument',
      download: 'healthAttestations.CTPCAttestations/*/document',
    },
  },
  onderneming: {
    geefOnderneming: 'Onderneming.GeefOnderneming',
    geefPCenTW: 'Onderneming.GeefPCenTW',
    geefDeelnemingen: 'Onderneming.GeefDeelnemingen',
  },
  docProvider: {
    admin: 'ebox.eboxAdmin',
  },
  kadaster: {
    zoekPerceel: 'Kadaster.ZoekPerceel',
    zoekEigendomstoestanden: 'Kadaster.ZoekEigendomstoestanden',
    geefEigendomstoestanden: 'Kadaster.GeefEigendomstoestanden',
    zoekVerkoopprijzen: 'Kadaster.ZoekVerkoopprijzen',
    geefHistoriekEigendomstoestand: 'Kadaster.GeefHistoriekEigendomstoestand',
  },
  pushNotifications: {
    managementReceivers: 'pushNotifications.management/receivers',
    deleteManagementReceivers: 'pushNotifications.management/receivers/*',
    postNotifications: 'pushNotifications.notifications',
    postNotificationsReceiver: 'pushNotifications.notifications/receivers/*',
    notificationsGetMembers: 'pushNotifications.members',
    notificationsPostMembers: 'pushNotifications.members',
    notificationsGetMember: 'pushNotifications.members/*',
    notificationsDeleteMember: 'pushNotifications.members/*',
    notificationsPutMember: 'pushNotifications.members/*',
    notificationsGetGroups: 'pushNotifications.groups',
    notificationsPostGroups: 'pushNotifications.groups',
    notificationsGroup: 'pushNotifications.groups/*',
  },
  bulk: {
    bulkJobs: 'bulk.jobs',
    bulkJobsResults: 'bulk.jobs/*/results',
  },
  socialSecurity: {
    geefSociaalStatuut: 'SocZek.GeefSociaalStatuut',
    geefLeefloon: 'SocZek.GeefLeefloon',
  },
  gezin: {
    geefKindVoordelen: 'Gezin.GeefKindVoordelen',
  },
}

export const GACategory = {
  profiel: {
    category: 'Profiel',
    refreshSession: 'RefreshSession',
    selectNewRole: 'SelectNewRole',
    zelfregistratie: 'Zelfregistratie',
  },
  LED: {
    category: 'LED',
    geefBewijs_Detail: 'GeefBewijs_Detail',
    geefBewijs_Overzicht: 'GeefBewijs_Overzicht',
    geefBewijs_Overzicht_Details_Exporteren: 'GeefBewijs_Overzicht_Details_Exporteren',
    geefBewijs_Overzicht_Exporteren: 'GeefBewijs_Overzicht_Exporteren',
    geefBewijs_Detail_Exporteren: 'GeefBewijs_Detail_Exporteren',
    bewijsToevoegen: 'BewijsToevoegen',
    bewijsToevoegen_Success: 'BewijsToevoegen_success',
    bewijsToevoegen_Error: 'BewijsToevoegen_error',
    annuleerBewijs: 'AnnuleerBewijs',
    annuleerBewijs_Success: 'AnnuleerBewijs_success',
    annuleerBewijs_Error: 'AnnuleerBewijs_error',
    downloadenMetadata: 'DownloadenMetadata',
    downloadenResultaatBestand: 'DownloadenResultaatBestand',
    startJob: 'StartJob',
    bekijkJob: 'BekijkJob',
  },
  persoon: {
    category: 'Persoon',
    zoekPersoonOpAdres: 'ZoekPersoonOpAdres',
    zoekPersoonOpAdres_Detail: 'ZoekPersoonOpAdres_Detail',
    zoekPersoonOpNaam: 'ZoekPersoonOpNaam',
    zoekPersoonOpNaam_Detail: 'ZoekPersoonOpNaam_Detail',
    zoekPersoonOpINSZ_MetHistoriek: 'ZoekPersoonOpINSZ_MetHistoriek',
    zoekPersoonOpINSZ_ZonderHistoriek: 'ZoekPersoonOpINSZ_ZonderHistoriek',
    zoekPersoonGezinssamenstelling: 'geefGezinssamenstelling',
    zoekPersoonGezinssamenstelling_MetHistoriek: 'geefHistoriekGezinssamenstelling',
    creeerBis: 'CreeerBis',
    creeerBis_Success: 'CreeerBis_Success',
    creeerBis_Error: 'CreeerBis_Error',
    detail_ToggleCompacteVariant: 'Detail_ToggleCompacteVariant',
    detail_ToggleHistoriek: 'Detail_ToggleHistoriek',
    detail_WijzigBron: 'Detail_WijzigBron',
    detail_MetHistoriek: 'Detail_MetHistoriek',
    detail_Compact: 'Detail_Compact',
    detail_Uitgebreid: 'Detail_Uitgebreid',
    detailPersoon_GaNaarBriefVerzending: 'DetailPersoon_GaNaarBriefVerzending',
    detailPersoon_GaNaarEboxVerzending: 'DetailPersoon_GaNaarEboxVerzending',
    detailGezinssamenstelling_GaNaarBriefVerzending: 'DetailGezinssamenstelling_GaNaarBriefVerzending',
    detailGezinssamenstelling_GaNaarEboxVerzending: 'DetailGezinssamenstelling_GaNaarEboxVerzending',
    detail_ExporteerGezinssamenstelling: 'Detail_ExporteerGezinssamenstelling',
    detail_ExporteerPDFDetailUitgebreid: 'Detail_ExporteerPDFDetailUitgebreid',
    detail_ExporteerPDFDetailCompact: 'Detail_ExporteerPDFDetailCompact',
  },
  gezinssamenstelling: {
    category: 'Gezinssamenstelling',
    zoekPersoonGezinssamenstelling: 'ZoekPersoonGezinssamenstelling',
    detail_ToggleHistoriek: 'Detail_ToggleHistoriek',
    detail_ZoekPersoonGezinssamenstelling: 'ZoekPersoonGezinssamenstelling_Detail',
    detail_ExporteerPDFDetailUitgebreid: 'Detail_ExporteerPDFDetailUitgebreid',
    detail_ExporteerPDFDetailCompact: 'Detail_ExporteerPDFDetailCompact',
  },
  Document: {
    category: 'Documentendienst',
    verzendBrief_ZoekadresmetINSZ: 'VerzendBrief_ZoekadresmetINSZ',
    verzendBrief_ZoekadresmetKBO: 'VerzendBrief_ZoekadresmetKBO',
    bulkSimple_OpladenAdressenBestand: 'BulkSimple_OpladenAdressenBestand',
    bulkAdvanced_OpladenAdressenBestand: 'BulkAdvanced_OpladenAdressenBestand',
    bulkSimple_VervangenAdressenBestand: 'BulkSimple_VervangenAdressenBestand',
    bulkAdvanced_VervangenAdressenBestand: 'BulkAdvanced_VervangenAdressenBestand',
    detail_DownloadAttachment: 'Detail_DownloadAttachment',
    zoekVerzendingen_Detail: 'ZoekVerzendingen_Detail',
    zoekVerzendingen: 'ZoekVerzendingen',
    startpagina: 'Startpagina',
    verzendBrief_Success: 'VerzendBrief_Success',
    bulkAdvanced_OverslaanBestemmeling: 'BulkAdvanced_OverslaanBestemmeling',
    bulkAdvanced_VerzendAutomatic_Success: 'BulkAdvanced_VerzendAutomatic_Success',
    bulkAdvanced_VerzendBrief_Success: 'BulkAdvanced_VerzendBrief_Success',
    bulkAdvanced_VerzendEmail_Success: 'BulkAdvanced_VerzendEmail_Success',
    bulkAdvanced_VerzendEboxOnderneming_Success: 'BulkAdvanced_VerzendEboxOnderneming_Success',
    bulkAdvanced_VerzendEboxBurger_Success: 'BulkAdvanced_VerzendEboxBurger_Success',
    bulkAdvanced_ResultaatPagina: 'BulkAdvanced_ResultaatPagina',
    bulkAdvanced_DownloadenResultaatBestand: 'BulkAdvanced_DownloadenResultaatBestand',
    bulkSimple_ResultaatPagina: 'BulkSimple_ResultaatPagina',
    bulkSimple_Verzend_Success: 'BulkSimple_Verzend_Success',
    bulkSimple_DownloadenResultaatBestand: 'BulkSimple_DownloadenResultaatBestand',
    verzendEboxOnderneming_Success: 'VerzendEboxOnderneming_Success',
    verzendEboxBurger_Success: 'VerzendEboxBurger_Success',
    verzendEmail_Success: 'VerzendEmail_Success',
    detailNietBeschikbaarNaVerzenden: 'DetailNietBeschikbaarNaVerzenden',
    detail_TrackAndTrace: 'Detail_TrackAndTrace',
    herverzenden_Brief: 'Herverzenden_Brief',
    herverzenden_Ebox: 'Herverzenden_Ebox',
    doclr_vaccinatielink: 'Doclr_vaccinatielink',
    publiekeDownload_Error: 'PubliekeDownload_Error',
    bulkSimple_StartJob: 'BulkSimple_StartJob',
    downloaden_Attest: 'Downloaden_attest',
  },
  legalLog: {
    category: 'LegalLog',
    zoekLegalLog_Detail: 'ZoekLegalLog_Detail',
    zoekLegalLog: 'ZoekLegalLog',
  },
  DIV: {
    category: 'Mobiliteit',
    zoekBuitenlandsVoertuig: 'ZoekBuitenlandsVoertuig',
    zoekBelgischVoertuig: 'ZoekBelgischVoertuig',
    detailVoertuig_GaNaarBriefVerzending: 'DetailVoertuig_GaNaarBriefVerzending',
    detailVoertuig_GaNaarEboxVerzending: 'DetailVoertuig_GaNaarEboxVerzending',
  },
  Attesten: {
    category: 'Attestations',
    sendDocument: 'SendDocument',
    searchInsz: 'SearchINSZ',
    searchName: 'SearchName',
    viewDetail: 'ViewDetail',
    generateAttestation: 'GenerateAttestation',
    downloadAttestation: 'DownloadAttestation',
    sendLatestDocument: 'SendLatestDocument',
    viewDocuments: 'ViewDocuments',
    searchCTPC: 'SearchCTPC',
    viewDetailCTPC: 'ViewDetailCTPC',
    generateAttestationCTPC: 'generateAttestationCTPC',
    downloadAttestationCTPC: 'DownloadAttestationCTPC',
    recreateAttestations: 'Recreate',
    revokeAttestations: 'RecreateRevoke',
    toevoegen1DosisVereist: 'Toevoegen1DosisVereist',
    verwijderen1DosisVereist: 'Verwijderen1DosisVereist',
  },
  Docprovider: {
    category: 'DocProvider',
    senderOrganisationsGet: 'senderOrganisations_GET',
    senderOrganisationsPost: 'senderOrganisations_POST',
    senderOrganisationsPost_succes: 'senderOrganisations_POST_succes',
    senderOrganisationsPost_error: 'senderOrganisations_POST_error',
    senderOrganisationsPut: 'senderOrganisations_PUT',
    senderOrganisationsPut_succes: 'senderOrganisations_PUT_succes',
    senderOrganisationsPut_error: 'senderOrganisations_PUT_error',
    senderOrganisationsDelete: 'senderOrganisations_DELETE',
    senderOrganisationsDelete_succes: 'senderOrganisations_DELETE_succes',
    senderOrganisationsDelete_error: 'senderOrganisations_DELETE_error',
    senderMessageTypesGet: 'senderMessageTypes_GET',
    senderMessageTypesPost: 'senderMessageTypes_POST',
    senderMessageTypesPost_succes: 'senderMessageTypes_POST_succes',
    senderMessageTypesPost_error: 'senderMessageTypes_POST_error',
    senderMessageTypesPut: 'senderMessageTypes_PUT',
    senderMessageTypesPut_succes: 'senderMessageTypes_PUT_succes',
    senderMessageTypesPut_error: 'senderMessageTypes_PUT_error',
    senderMessageTypesDelete: 'senderMessageTypes_DELETE',
    senderMessageTypesDelete_succes: 'senderMessageTypes_DELETE_succes',
    senderMessageTypesDelete_error: 'senderMessageTypes_DELETE_error',
    senderApplicationGet: 'senderApplications_GET',
    senderApplicationPost: 'senderApplications_POST',
    senderApplicationPost_succes: 'senderApplications_POST_succes',
    senderApplicationPost_error: 'senderApplications_POST_error',
    senderApplicationPut: 'senderApplications_PUT',
    senderApplicationPut_succes: 'senderApplications_PUT_succes',
    senderApplicationPut_error: 'senderApplications_PUT_error',
    senderApplicationDelete: 'senderApplications_DELETE',
    senderApplicationDelete_succes: 'senderApplications_DELETE_succes',
    senderApplicationDelete_error: 'senderApplications_DELETE_error',
    senderApplicationsMessagesPut: 'senderApplications_Messages_PUT',
    senderApplicationsMessagesPut_succes: 'senderApplications_Messages_PUT_succes',
    senderApplicationsMessagesPut_error: 'senderApplications_Messages_PUT_error',
  },
  socialSecurity: {
    category: 'SocialeZekerheid',
    geefSociaalStatuut: 'GeefSociaalStatuut',
    geefLeefloon: 'LeefloonAdvies',
  },
  kadaster: {
    category: 'Kadaster',
    zoekCapakeyOnvolledig: 'ZoekCapakeyOnvolledig',
    zoekCapakey: 'ZoekCapakey',
    geefEigendomstoestand: 'GeefEigendomstoestand',
    geefEigendomstoestandHistoriek: 'GeefEigendomstoestandHistoriek',
    zoekEigendomstoestand: 'ZoekEigendomstoestand',
    detailEigendomstoestand: 'DetailEigendomstoestand',
    zoekEigenaar: 'ZoekEigenaar',
    zoekEigenaarHistoriek: 'ZoekEigenaarHistoriek',
    zoekAdres: 'ZoekAdres',
    zoekOpKaart: 'ZoekOpKaart',
    zoekVerkoopprijzen: 'ZoekVerkoopprijzen',
    zoekVerkoopprijzenDetail: 'ZoekVerkoopprijzen_Detail',
    zoekVerkoopprijzenDownload: 'ZoekVerkoopprijzen_Download',
    geefHistoriekEigendomstoestand: 'GeefHistoriekEigendomstoestand',
    geefHistoriekEigendomstoestandEigenaars: 'GeefHistoriekEigendomstoestand_Eigenaars',
    downloadEigenaars: 'DownloadEigenaars',
    downloadEigenaarsStartJob: 'DownloadEigenaarsStartJob',
    downloadEigenaarsBulk: 'DownloadEigenaarsBulk',
    zoekPerceelViaBestand: 'ZoekPerceelViaBestand',
  },
  pushNotifications: {
    category: 'PushNotification',
    zoekOntvanger: 'zoekOntvanger',
    verwijderDevice: 'verwijderDevice',
    verzendNotificatie: 'verzendNotificatie',
    verzendNotificatie_Success: 'verzendNotificatie_success',
    verzendNotificatie_Error: 'verzendNotificatie_error',
    downloadenResultaatBestand: 'DownloadenResultaatBestand',
    startJob: 'StartJob',
    bekijkJob: 'BekijkJob',
    lidToevoegen: ' LidToevoegen',
    lidWijzigen: ' LidWijzigen',
    lidDetail: 'LidDetail',
    verwijderenLeden: 'VerwijderenLeden',
    ledenZoeken: 'LedenZoeken',
    groepZoeken: 'GroepZoeken',
    verzendNotificatieGroep: 'VerzendNotificatieGroep',
    verzendNotificatieTest: 'VerzendNotificatieTest ',
  },
  gezin: {
    category: 'Gezin',
    geefKindvoordelen: 'geefKindvoordelen',
    geefKindvoordelen_ExporteerPDF: 'GeefKindvoordelen_ExporteerPDF',
  },
  onderneming: {
    category: 'Onderneming',
    geefOnderneming: 'geefOnderneming',
    geefPCenTW: 'geefPCenTW',
    geefDeelnemingen_Deelnemers: 'geefDeelnemingen_Deelnemers',
    geefDeelnemingen_Deelname: 'geefDeelnemingen_Deelname',
  },
  auditLegalLog: {
    category: 'AuditLegalLog',
    consulteerLegalLog: 'ConsulteerLegalLog',
    consulteerLegalLogDownload: 'ConsulteerLegalLogDownload',
  },
  rapportering: {
    category: 'Rapportering',
    bekijkRapport: 'BekijkRapport_',
  },
}

export const FriendlyErrorCodes = {
  InternalServerError: 4,
  ExternalServiceSoapFout: 181,
  MagdaSysteemFout: 92,
  MagdaOnverwachteFout: 93,
  MagdaExtraInformatieWaarschuwing: 237,
  MagdaFoutToegangTotBron: 248,
  MagdaFoutHealtAttestationErrorCallingGeefPersoon: 249,
  MagdaFoutHealtAttestationMessageRecentlySent: 250,
  MagdaFoutHealtAttestationNoAddressFound: 251,
  MagdaFoutHealtAttestationAddressFoundButNotValid: 252,
  MagdaDocProviderRemoveOrganisationAlreadyInUseForMessages: 291,
  MagdaDocProviderRemoveApplicationAlreadyInUseForMessages: 292,
}

export const VipParameters = {
  Logging: {
    referentieLegalLog: 'Referentie_legallog',
  },
  Gezin: {
    betalingsperiode: 'betalingsperiode_type',
  },
}
