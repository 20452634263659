import { render, staticRenderFns } from "./ProofInfo.html?vue&type=template&id=c447c9b8&scoped=true&external"
import script from "./ProofInfo.ts?vue&type=script&lang=ts&external"
export * from "./ProofInfo.ts?vue&type=script&lang=ts&external"
import style0 from "./ProofInfo.vue?vue&type=style&index=0&id=c447c9b8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c447c9b8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/6/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c447c9b8')) {
      api.createRecord('c447c9b8', component.options)
    } else {
      api.reload('c447c9b8', component.options)
    }
    module.hot.accept("./ProofInfo.html?vue&type=template&id=c447c9b8&scoped=true&external", function () {
      api.rerender('c447c9b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/leer_en_ervaring/proof-info/ProofInfo.vue"
export default component.exports